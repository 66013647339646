import React from 'react';
import {Space, Table} from "antd";
import {Link} from "react-router-dom";

const RestaurantReservationList = ({reservations, isLoading}) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Синхронизация с Bitrix',
            key: 'syncWithBitrix',
            render: (text, record) => (
                record.syncWithBitrix ? 'Успешно' : 'Не успешно'
            )
        },
        {
            title: 'Restaurant id',
            dataIndex: 'restaurantId',
            key: 'restaurantId'
        },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.userId}`}>{record.userId}</Link>
                </Space>
            )
        },
        {
            title: 'Время брони',
            dataIndex: 'reservationDate',
            key: 'reservationDate'
        },
        {
            title: 'Кол-во персон',
            dataIndex: 'personCount',
            key: 'personCount'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {/*<Button onClick={() => router.push(`/hotels/${record.id}`)}>Редактировать</Button>*/}
                </Space>
            )
        }
    ];

    return (
        <Table columns={columns} dataSource={reservations} rowKey='id' loading={isLoading}/>
    );
};

export default RestaurantReservationList;