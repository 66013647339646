import React, {useEffect, useState} from 'react';
import {Button, Image, Space, Table} from "antd";
import {useFetching} from "../../hooks/useFetching";
import ProductOrderService from "../../API/ProductOrderService";
import {Link} from "react-router-dom";

const ProductOrders = () => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Синхронизация с Bitrix',
            key: 'syncWithBitrix',
            render: (text, record) => (
                record.syncWithBitrix ? 'Успешно' : 'Не успешно'
            )
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.productImageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Наименование на рус.',
            key: 'nameRu',
            render: (text, record) => (
                record.productName.ru
            )
        },
        {
            title: 'Итого',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.userId}`}>{record.userName}</Link>
                </Space>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => updateStatus(record.id, 'COMPLETED')}>Завершить</Button>
                    <Button type="danger" onClick={() => updateStatus(record.id, 'CANCELLED')}>Отменить</Button>
                </Space>
            )
        }
    ];

    const [updateStatus, isUpdating] = useFetching(async (id, status) => {
       await ProductOrderService.updateStatus(id, status);
       await fetchOrders(pagination.current, pagination.pageSize);
    });

    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const [fetchOrders, isLoading] = useFetching(async (page, size) => {
        const {data} = await ProductOrderService.getAll(page, size);
        setOrders(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchOrders(pagination.current, pagination.pageSize);
    }


    useEffect(() => {
        fetchOrders(pagination.current, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <Table columns={columns}
                   dataSource={orders}
                   rowKey='id'
                   loading={isLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
        </div>
    );
};

export default ProductOrders;