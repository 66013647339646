import React from 'react';
import {Modal, Input, Alert, Button, Form} from "antd";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";

const ChangePasswordModal = ({visible, setVisible, user}) => {

    const [form] = Form.useForm();

    const [updatePassword, isPasswordUpdating, passwordError] = useFetching(async (newPassword) => {
        await UserService.changePassword(user.id, newPassword);
        setVisible(false);
        form.resetFields();
    });

    const handleOk = async ({newPassword}) => {
        await updatePassword(newPassword);
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <Modal
            title={`Изменить пароль пользователю ${user.firstName} ${user.lastName}`}
            visible={visible}
            footer={[
                <Button type="primary" form="newPasswordForm" key="submit" htmlType="submit">
                    Сохранить
                </Button>
            ]}
            confirmLoading={isPasswordUpdating}
            onCancel={handleCancel}
        >
            {passwordError && <Alert message={passwordError} type="error"/>}
            <Form
                form={form}
                id="newPasswordForm"
                autoComplete="off"
                onFinish={handleOk}
            >
                <Form.Item
                    label="Новый пароль"
                    name="newPassword"
                    rules={[{required: true, message: 'Пароль не может быть пустым!'},
                        {min: 8, max: 16, message: 'Длина пароля должна быть от 8 до 16!'}]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;