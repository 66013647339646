import React, {useContext, useEffect} from 'react';
import './Login.css';
import {Form, Input, Button, message} from 'antd';
import axios from "axios";
import {AuthContext} from "../../context";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import {LockOutlined, UserOutlined} from "@ant-design/icons";


const Login = () => {

    const {setIsAuth, setUser} = useContext(AuthContext);

    const [login, isLoginLoading, loginError] = useFetching(async (email, password) => {
        const {data} = await UserService.login(email, password);

        if (data.roles.indexOf('ADMIN') === -1) {
            message.error('Нет доступа!');
            return;
        }

        message.success('Успешная авторизация');
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('user', JSON.stringify(data.user));
        setIsAuth(true);
        setUser(data.user);
    })

    const onFinish = async ({email, password}) => {
        await login(email, password);
    };

    useEffect(() => {
        if (loginError) {
            message.error(loginError);
        }
    }, [loginError]);

    return (
        <div className="login">
            <div>
                <img src="/logo.png" className="login-logo" alt="logo"/>
            </div>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: 'Пожалуйста, введите Ваш email!',
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        placeholder="Email"
                        className="login-form-input"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйтса, введите Ваш пароль!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Password"
                        className="login-form-input"
                    />
                </Form.Item>
                <Form.Item>
                    <a className="login-form-forgot" href="/">
                        Забыли пароль?
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" className="login-form-button" loading={isLoginLoading}>
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;