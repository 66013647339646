import axios from "axios";

export default class NewsService {

    static async getAll() {
        return await axios.get('/content-service/admin/news');
    }

    static async getOne(id) {
        return await axios.get(`/content-service/news/${id}`)
    }

    static async create(data) {
        return await axios.post('/content-service/admin/news', data);
    }

    static async update(id, data) {
        return await axios.put(`/content-service/admin/news/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/content-service/admin/news/${id}`);
    }
}