import axios from "axios";

export default class NotificationService {
    static async getAll() {
        return await axios.get('/notification-service/admin/notifications');
    }

    static async create(data) {
        return await axios.post('/notification-service/admin/notifications', data);
    }

    static async update(id, data) {
        return await axios.put(`/notification-service/admin/notifications/${id}`, data);
    }

    static async getById(id) {
        return await axios.get(`/notification-service/admin/notifications/${id}`);
    }

    static async delete(id) {
        return await axios.delete(`/notification-service/admin/notifications/${id}`);
    }

    static async send(id) {
        return await axios.post(`/notification-service/admin/notifications/${id}/send`);
    }
}