import React from 'react';
import axios from 'axios';
import {Button, Upload, message} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const UploadImage = ({setImageUrl, setIsUploading, title}) => {

    const onChange = (info) => {
        if (info.file.status === 'uploading') {
            setIsUploading(true);
        }
        if (info.file.status === 'done') {
            setImageUrl(info.file.response.url);
            message.success(`Изображение загрузилось!`);
            setIsUploading(false);
        } else if (info.file.status === 'error') {
            message.error(`Ошибка загрузки изображения`);
            setIsUploading(false);
        }
    }

    return (
        <Upload 
            action={"https://api.oiqaragai.com/" + "file-service/admin/files/upload-image"}
            customRequest={
                (options) => {
                    const data = new FormData()
                    data.append("image", options.file)
                    const config= {
                        "headers": {
                            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    axios.post(options.action, data, config)
                        .then((res) => {
                            options.onSuccess(res.data, options.file);
                        }).catch((err) => {
                            console.log(err);
                        })
                }
            }
            method="post"
            name="image" 
            type="file"
            maxCount={1}
            onChange={onChange}
        >
            <Button icon={<UploadOutlined/>}>{title ? title : 'Загрузить изображение'}</Button>
        </Upload>
    );
};

export default UploadImage;