import axios from "axios";

export default class LiveVideoService {
    static async getAllLiveVideos() {
        return await axios.get('/content-service/live-videos');
    }

    static async createLiveVideo({titleRu, titleKk, titleEn, videoUrl}) {
        return await axios.post('/content-service/live-videos', {
            titleRu, titleKk, titleEn, videoUrl
        });
    }

    static async updateLiveVideo({id, titleRu, titleKk, titleEn, videoUrl}) {
        return await axios.put(`/content-service/live-videos/${id}`, {
            titleRu, titleKk, titleEn, videoUrl
        });
    }

    static async deleteLiveVideo(id) {
        return await axios.delete(`/content-service/live-videos/${id}`);
    }
}