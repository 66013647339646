import React, {useEffect, useState} from 'react';
import {Button, Descriptions, Image, message, PageHeader, Space, Table} from "antd";
import {useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import HotelService from "../../API/HotelService";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const Rooms = () => {

    const params = useParams();
    const router = useHistory();

    const [hotel, setHotel] = useState({});
    const [fetchHotel, isHotelLoading] = useFetching(async (id) => {
        const {data} = await HotelService.getById(id);
        setHotel(data);
    })

    useEffect(() => {
        fetchHotel(params.id);
    }, []);

    const [rooms, setRooms] = useState([]);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Показывать?',
            key: 'enabled',
            render: (text, record) => (
                <div>{record.enabled ? 'Да' : 'Нет'}</div>
            )
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'titleRu',
            key: 'titleRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'titleKk',
            key: 'titleKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'titleEn',
            key: 'titleEn'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/hotels/${params.id}/rooms/${record.id}`)}>Редактировать</Button>
                    <Button type="danger" onClick={() => onDelete(record)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [fetchRooms, isLoading] = useFetching(async (hotelId) => {
        const {data} = await HotelService.getRooms(hotelId);
        setRooms(data);
    });

    useEffect(() => {
        fetchRooms(params.id);
    }, [])

    const [deleteRoom] = useFetching(async (hotelId, id) => {
        await HotelService.deleteRoom(hotelId, id);
        message.success('Номер удален');
        await fetchRooms(hotelId);
    })

    const onDelete = async (room) => {
        Modal.confirm({
            title: 'Удалить номер?',
            icon: <ExclamationCircleOutlined />,
            content: `${room.titleRu} будет удален`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return deleteRoom(hotel.id, room.id);
            },
            onCancel() {},
        });
    }

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Номерной фонд"
                subTitle={hotel.titleRu}
                extra={[
                    <Button type="primary" onClick={() => router.push(`/hotels/${params.id}/rooms/new`)}>
                        Добавить номер
                    </Button>
                ]}
            />
            <Table columns={columns} dataSource={rooms} rowKey='id' loading={isLoading}/>
        </div>
    );
};

export default Rooms;