import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import {Button, Image, Space, Table} from "antd";
import ProductService from "../../API/ProductService";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const Products = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Артикул',
            dataIndex: 'article',
            key: 'article'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Показывать?',
            key: 'enabled',
            render: (text, record) => (
                <div>{record.enabled ? 'Да' : 'Нет'}</div>
            )
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'nameRu',
            key: 'nameRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'nameKk',
            key: 'nameKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'nameEn',
            key: 'nameEn'
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Старая цена',
            dataIndex: 'oldPrice',
            key: 'oldPrice'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/merchant/products/${record.id}`)}>Редактировать</Button>
                    <Button type="danger" onClick={() => onDelete(record)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [deleteProduct, isDeleting] = useFetching(async (id) => {
        await ProductService.delete(id);
        await fetchProducts();
    });

    const onDelete = async (product) => {
        Modal.confirm({
            title: 'Удалить товар?',
            icon: <ExclamationCircleOutlined />,
            content: `${product.nameRu} будет удален`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return deleteProduct(product.id);
            },
            onCancel() {},
        });
    }


    const router = useHistory();
    const [products, setProducts] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const [fetchProducts, isLoading] = useFetching(async (page, size) => {
        const {data} = await ProductService.getAll(page, size);
        setProducts(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchProducts(pagination.current, pagination.pageSize);
    }


    useEffect(() => {
        fetchProducts(pagination.current, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => router.push('/merchant/products/new')}
            >
                Добавить товар
            </Button>
            <Table columns={columns}
                   dataSource={products}
                   rowKey='id'
                   loading={isLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
        </>
    );
};

export default Products;