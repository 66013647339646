import axios from "axios";

export default class HotelService {
    static async getAll() {
        return await axios.get('/hotel-service/admin/hotels');
    }

    static async getById(id) {
        return await axios.get(`/hotel-service/admin/hotels/${id}`);
    }

    static async create(data) {
        return await axios.post('/hotel-service/admin/hotels', data);
    }

    static async update(id, data) {
        return await axios.put(`/hotel-service/admin/hotels/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/hotel-service/admin/hotels/${id}`);
    }

    static async getRooms(hotelId) {
        return await axios.get(`/hotel-service/admin/hotels/${hotelId}/rooms`);
    }

    static async getRoom(hotelId, id) {
        return await axios.get(`/hotel-service/admin/hotels/${hotelId}/rooms/${id}`);
    }

    static async createRoom(hotelId, data) {
        return await axios.post(`/hotel-service/admin/hotels/${hotelId}/rooms`, data);
    }

    static async updateRoom(hotelId, id, data) {
        return await axios.put(`/hotel-service/admin/hotels/${hotelId}/rooms/${id}`, data);
    }

    static async deleteRoom(hotelId, id) {
        return await axios.delete(`/hotel-service/admin/hotels/${hotelId}/rooms/${id}`);
    }
}