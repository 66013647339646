import axios from "axios";

export default class IncludedPaymentService {
    static async getTypes() {
        return await axios.get('/payment-service/api/included-payments/get-types');
    }

    static async getAll() {
        return await axios.get('/payment-service/api/included-payments/all');
    }

    static async create(payload) {
        return await axios.post('/payment-service/api/included-payments/create', null, {
            params: {
                paymentName: payload.paymentName,
                active: payload.active,
            }
        });
    }

    static async createType(payload) {
        return await axios.post('/payment-service/api/included-payments/create-type', {
            name: payload.name,
        });
    }

    static async update(payload) {
        const id = payload.id;
        const params = {
            typeNames: payload.typeNames,
            index: payload.index,
            isActive: payload.active,
            paymentName: payload.paymentName,
        };
        return await axios.put(`/payment-service/api/included-payments/update/${id}`, null, {
            params: params,
            paramsSerializer: params => {
                return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
            }
        });
    }

    static async deleteMethod(payload) {
        const id = payload.id;
        return await axios.delete(`/payment-service/api/included-payments/payments/${id}`);
    }

    static async deletePaymentType(payload) {
        const includedPaymentId = payload.includedPaymentId;
        const typeName = payload.typeName;
        return await axios.delete(`/payment-service/api/included-payments/${includedPaymentId}/types/${typeName}`);
    }
}