import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import {Button, Space, Table, Tag} from "antd";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import FeedbackService from "../../API/FeedbackService";

const PartnerLoyalty = () => {
    const [partnerLoyalties, setPartnerLoyalties] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const handleTableChange = async (pagination) => {
        await fetchPartnerLoyalties(pagination.current, pagination.pageSize);
    }

    const [fetchPartnerLoyalties, isLoading] = useFetching(async (page, size) => {
        const {data} = await UserService.getAllPartnerLoyalty(page, size);
        setPartnerLoyalties(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const [cancelParnterLoyalty] = useFetching(async (emailOrDomain, action) => {
        if (action === 'CANCEL_BY_EMAIL') {
            await UserService.cancelPartnerLoyaltyByEmail(emailOrDomain);
        } else {
            await UserService.cancelPartnerLoyaltyByDomain(emailOrDomain);
        }
        await handleTableChange(pagination);
    });

    const onAction = async (partnerLoyalty, action) => {
        Modal.confirm({
            title: `${action === 'CANCEL_BY_EMAIL' ? 'Отменить по email' : 'Отменить по domain'}?`,
            icon: <ExclamationCircleOutlined/>,
            content: `${action === 'CANCEL_BY_EMAIL' ? partnerLoyalty.email : partnerLoyalty.emailDomain}`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return cancelParnterLoyalty(
                    action === 'CANCEL_BY_EMAIL'
                        ? partnerLoyalty.email
                        : partnerLoyalty.emailDomain, action
                );
            },
            onCancel() {
            },
        });
    }

    useEffect(() => {
        fetchPartnerLoyalties(pagination.current, pagination.pageSize);
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'PENDING') {
                    return <Tag icon={<SyncOutlined spin/>} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'ACTIVE') {
                    return <Tag icon={<CheckCircleOutlined/>} color="success">
                        Активный
                    </Tag>
                } else if (record.status === 'EXPIRED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Просрочен
                    </Tag>
                }
            }
        },
        {
            title: 'Пользователь',
            key: 'user.id',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.user.id}`}>
                        {(record.user.firstName || record.user.lastName)
                            ? `${record.user.firstName} ${record.user.lastName}`
                            : record.user.id
                        }
                    </Link>
                </Space>
            )
        },
        {
            title: 'Phone',
            key: 'user.phone',
            render: (text, record) => (
                <Space size="middle">{record.user.phone}</Space>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'emailDomain',
            dataIndex: 'emailDomain',
            key: 'emailDomain'
        },
        {
            title: 'cardNameBefore',
            dataIndex: 'cardNameBefore',
            key: 'cardNameBefore'
        },
        {
            title: 'cardName',
            dataIndex: 'cardName',
            key: 'cardName'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button danger disabled={record.status === 'EXPIRED'} onClick={() => onAction(record, 'CANCEL_BY_EMAIL')}>Отменить по
                        email</Button>
                    <Button danger onClick={() => onAction(record, 'CANCEL_BY_DOMAIN')}>Отменить по
                        domain</Button>
                </Space>
            )
        }
    ];

    return (<>
        <Table columns={columns}
               dataSource={partnerLoyalties}
               rowKey='id'
               loading={isLoading}
               pagination={pagination}
               onChange={handleTableChange}
        />
    </>);
}

export default PartnerLoyalty;