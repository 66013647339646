import React from 'react';
import {Modal, Input, Alert, Button, Form, InputNumber} from "antd";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import BonusService from "../../API/BonusService";

const BonusModal = ({visible, setVisible, user, operationType, amount}) => {

    const [form] = Form.useForm();

    const [updateBonus, isBonusUpdating, bonusError] = useFetching(async (comment, amount) => {
        if (operationType === 'ADD') {
            await BonusService.addBalance(user.id, {comment, amount});
        } else {
            await BonusService.withdrawBalance(user.id, {comment, amount});
        }
        setVisible(false);
        form.resetFields();
    });

    const handleOk = async ({comment, amount}) => {
        await updateBonus(comment, amount);
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <Modal
            title={`${operationType === 'ADD' ? 'Начислить' : 'Снять'} бонусы пользователю ${user.firstName} ${user.lastName}`}
            visible={visible}
            footer={[
                <Button type="primary" form="bonusForm" key="submit" htmlType="submit">
                    Сохранить
                </Button>
            ]}
            confirmLoading={isBonusUpdating}
            onCancel={handleCancel}
        >
            {bonusError && <Alert message={bonusError} type="error"/>}
            <Form
                form={form}
                id="bonusForm"
                autoComplete="off"
                onFinish={handleOk}
            >
                <Form.Item name="comment" label="Сообщение в пуш-уведомлений" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Кол-во бонусов"
                    name="amount"
                    rules={[{required: true, message: 'Пароль не может быть пустым!'},
                        {type: 'number', min: 1, message: 'Кол-во бонусов должно быть больше 0!'}]}
                >
                    <InputNumber/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BonusModal;