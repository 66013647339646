import axios from "axios";

export default class AirbaService {
    static async getCompletedOrders(page) {
        const params = {
            page,
        };
        return await axios.get('/order-service/admin/orders/airba', { params });
    }

    static async cancelById(id) {
        return await axios.post(`/payment-service/admin/payments/airba/cancel?invoiceId=${id}`);
    }
}