import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import {Button, Space, Table} from "antd";
import {Link} from "react-router-dom";
import SuriService from "../../API/SuriService";
import OrderService from "../../API/OrderService";

const SuriOrders = () => {

    const columns = [
        {
            title: 'Invoice ID',
            dataIndex: 'invoiceId',
            key: 'invoiceId'
        },
        {
            title: 'Синхронизация с SURI',
            key: 'syncWithSuri',
            render: (text, record) => (
                record.syncWithSuri ? 'Успешно' : 'Не успешно'
            )
        },
        {
            title: 'Итого',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.userId}`}>{record.userName}</Link>
                </Space>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        }
    ];

    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const [fetchOrders, isLoading] = useFetching(async (page, size) => {
        const {data} = await SuriService.getAll(page, size);
        setOrders(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchOrders(pagination.current, pagination.pageSize);
    }


    useEffect(() => {
        fetchOrders(pagination.current, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Table columns={columns}
                   dataSource={orders}
                   rowKey='id'
                   loading={isLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
        </div>
    );
};

export default SuriOrders;