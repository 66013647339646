import axios from "axios";

export default class OnboardingService {
    static async getAll() {
        return await axios.get('/content-service/admin/onboarding');
    }

    static async create(data) {
        return await axios.post('/content-service/admin/onboarding', data);
    }

    static async update(id, data) {
        return await axios.put(`/content-service/admin/onboarding/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/content-service/admin/onboarding/${id}`);
    }
}