import React, {useEffect} from 'react';
import Modal from "antd/es/modal/Modal";
import {Button, Form, Input} from "antd";
import {useFetching} from "../../hooks/useFetching";
import LiveVideoService from "../../API/LiveVideoService";

const CreateOrUpdateLiveModal = ({model, visible, onClose}) => {

    const [form] = Form.useForm();

    const [createOrUpdateLive, isLoading, errors] = useFetching(async ({titleRu, titleKk, titleEn, videoUrl}) => {
        if (model) {
            await LiveVideoService.updateLiveVideo({id: model.id, titleRu, titleKk, titleEn, videoUrl});
        } else {
            await LiveVideoService.createLiveVideo({titleRu, titleKk, titleEn, videoUrl});
        }
        form.resetFields();
        onClose();
    })

    const handleOk = async (values) => {
        await createOrUpdateLive(values);
    };
    const handleCancel = () => {
        onClose(false);
        form.resetFields();
    }

    useEffect(() => {
        if (model) {
            form.setFieldsValue({
                titleRu: model.titleRu,
                titleKk: model.titleKk,
                titleEn: model.titleEn,
                videoUrl: model.videoUrl,
            });
        } else {
            form.resetFields();
        }
    }, [model])

    return (
        <Modal
            title={model ? 'Редактировать Live video' : 'Создать Live video'}
            onCancel={handleCancel}
            footer={[
                <Button type="primary" form="liveVideoForm" key="submit" htmlType="submit">
                    Сохранить
                </Button>
            ]}
            confirmLoading={isLoading}
            visible={visible}>
            <Form
                form={form}
                id="liveVideoForm"
                autoComplete="off"
                onFinish={handleOk}
            >
                <Form.Item
                    name="titleRu"
                    rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                >
                    <Input value="asdasd" placeholder="Наименование на рус."/>
                </Form.Item>
                <Form.Item
                    name="titleKk"
                    rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                >
                    <Input placeholder="Наименование на каз."/>
                </Form.Item>
                <Form.Item
                    name="titleEn"
                    rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                >
                    <Input placeholder="Наименование на анг."/>
                </Form.Item>
                <Form.Item
                    name="videoUrl"
                    rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                >
                    <Input placeholder="Ссылка на видео"/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateOrUpdateLiveModal;