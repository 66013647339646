import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import AirbaService from "../../API/AirbaService";
import OrderList from "../../components/orders/OrderList";
import UserService from "../../API/UserService";

const Installments = () => {
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 50,
    });

    const [fetchOrders, isLoading] = useFetching(async (page, size, userId ) => {
        const {data} = await AirbaService.getCompletedOrders(page - 1, size, userId);
        const userResponse = await UserService.getAllByIds([...new Set(data.content.map(order => order.userId))]);

        const users = Object.assign({}, ...userResponse.data.map((it) => ({[it.id]: it})));

        data.content.forEach(order => {
            const user = users[order.userId];
            order.userName = user && (user.lastName || user.firstName)
                ? `${user.lastName} ${user.firstName}` : (user ? user.id : 'USER DELETED');
        });

        setOrders(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: pagination.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchOrders(pagination.current, pagination.pageSize, null, null);
    }

    const handleAirbaCancel = async (order) => {
        await AirbaService.cancelById(order.orderNum);
        await fetchOrders(pagination.current, pagination.pageSize);
    };

    useEffect(() => {
        fetchOrders(pagination.current, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <OrderList orders={orders || []}
                isLoading={isLoading}
                pagination={pagination}
                handleTableChange={handleTableChange}
                isShowingCancelButton={true}
                handleCancel={handleAirbaCancel}
            />
        </div>
    );
};

export default Installments;