import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import AxessService from "../../API/AxessService";
import {Button, Space, Table} from "antd";
import {useHistory} from "react-router-dom";

const AxessMetaTariffs = () => {

    const router = useHistory();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'ID для Strapi(metaTariffId)',
            dataIndex: 'metaTariffId',
            key: 'metaTariffId'
        },
        {
            title: 'Кол-во дней для показа',
            dataIndex: 'daysToRequest',
            key: 'daysToRequest'
        },
        {
            title: 'Пул',
            dataIndex: 'poolName',
            key: 'poolName'
        },
        {
            title: 'personTypeName',
            dataIndex: 'personTypeName',
            key: 'personTypeName'
        },
        {
            title: 'ticketTypeName',
            dataIndex: 'ticketTypeName',
            key: 'ticketTypeName'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/axess/meta-tariffs/${record.id}`)}>Тарифы по дням</Button>
                    <Button type="primary" onClick={() => updateMetaTariff(record.id)} loading={isMetaTariffUpdating}>Обновить тарифы</Button>
                </Space>
            )
        }
    ];

    const [pools, setPools] = useState([]);

    const [fetchMetaTariffs, isLoading] = useFetching(async () => {
        const response = await AxessService.getAllMetaTariffs();
        setPools(response.data);
    });

    useEffect(() => {
        fetchMetaTariffs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [updateTariffs, isUpdating] = useFetching(async () => {
        await AxessService.updateAll();
        await fetchMetaTariffs();
    });

    const [updateMetaTariff, isMetaTariffUpdating] = useFetching(async (id) => {
        await AxessService.updateTariffs(id);
    });

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                loading={isUpdating}
                onClick={updateTariffs}
            >
                Обновить все тарифы
            </Button>
            <Table columns={columns} dataSource={pools} rowKey='id' loading={isLoading} pagination={false} />
        </>
    );
};

export default AxessMetaTariffs;