import {Alert, Button, Form, InputNumber, Modal} from "antd";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import React, {useEffect} from "react";

const ChangeTngProfileId = ({visible, setVisible, user, onSave}) => {

    const [form] = Form.useForm();

    const [updateTngProfileId, isTngProfileIdUpdating, tngProfileIdError] = useFetching(async (tngProfileId) => {
        await UserService.updateTngProfileId(user.id, tngProfileId);
        setVisible(false);
        form.resetFields();
    });

    const handleOk = async ({tngProfileId}) => {
        await updateTngProfileId(tngProfileId);
        onSave();
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                tngProfileId: user.tngProfileId
            });
        }
    }, [user]);

    return (
        <Modal
            title={`Изменить tngProfileId пользователю ${user.firstName} ${user.lastName}`}
            visible={visible}
            footer={[
                <Button type="primary" form="tngProfileIdForm" key="submit" htmlType="submit">
                    Сохранить
                </Button>
            ]}
            confirmLoading={isTngProfileIdUpdating}
            onCancel={handleCancel}
        >
            {tngProfileIdError && <Alert message={tngProfileIdError} type="error"/>}
            <Form
                form={form}
                id="tngProfileIdForm"
                autoComplete="off"
                onFinish={handleOk}
            >
                <Form.Item
                    label="Tng profile ID"
                    name="tngProfileId"
                    rules={[{required: true, message: 'Tng profile ID не может быть пустым!'}]}
                >
                    <InputNumber controls={false}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangeTngProfileId;