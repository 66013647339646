import axios from "axios";

export default class PaymentService {
    static async getAll(page) {
        const params = {
            page
        };
        return await axios.get('/payment-service/admin/payments/events', { params });
    }

    static async getReceipts(page, size, invoiceId) {
        return await axios.get(`/payment-service/admin/payments/receipts?page=${page - 1}&size=${size}&searchTerm=${invoiceId}`);
    }

    static async cancelById(id) {
        return await axios.post(`/payment-service/admin/payments/cancel?invoiceId=${id}`);
    }
}