import axios from "axios";

export default class UserService {
    static async getAll(page, size, searchTerm) {
        return await axios.get(`/user-profile-service/admin/users?page=${page - 1}&size=${size}&searchTerm=${searchTerm}`);
    }

    static async getByUserId(userId) {
        return await axios.get(`/user-profile-service/admin/users/${userId}`);
    }

    static async login(email, password) {
        return await axios.post('/user-profile-service/auth', {email, password, withRoles: true});
    }

    static async refreshToken(refreshToken) {
        return await axios.post('/user-profile-service/auth/refresh-token', {refreshToken});
    }

    static async changePassword(userId, newPassword) {
        return await axios.put(`/user-profile-service/admin/users/${userId}/change-password`, {newPassword});
    }

    static async getAllByIds(userIds) {
        return await axios.post('/user-profile-service/admin/users/get-by-ids', userIds)
    }

    static async getUsersStatistics() {
        return await axios.get('/user-profile-service/admin/users/statistics')
    }

    static async getTngProfilesByUserId(userId) {
        return await axios.get(`/user-profile-service/admin/users/${userId}/tng-profiles`);
    }

    static async updateTngProfileId(userId, tngProfileId) {
        return await axios.put(`/user-profile-service/admin/users/${userId}/tng-profile-id`, {tngProfileId});
    }

    static async getAllPartnerLoyalty(page, size) {
        return await axios.get(`/user-profile-service/partnership/page?page=${page - 1}&size=${size}&sort=createdAt,desc`);
    }

    static async cancelPartnerLoyaltyByEmail(email) {
        return await axios.post(`/user-profile-service/partnership/cancel?email=${email}`);
    }

    static async cancelPartnerLoyaltyByDomain(emailDomain) {
        return await axios.post(`/user-profile-service/partnership/cancelByDomain?emailDomain=${emailDomain}`);
    }
}