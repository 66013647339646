import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import {Button, Image, message, Space, Table} from "antd";
import {useHistory} from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import HotelService from "../../API/HotelService";


const Hotels = () => {

    const router = useHistory();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Показывать?',
            key: 'enabled',
            render: (text, record) => (
                <div>{record.enabled ? 'Да' : 'Нет'}</div>
            )
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'titleRu',
            key: 'titleRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'titleKk',
            key: 'titleKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'titleEn',
            key: 'titleEn'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/hotels/${record.id}`)}>Редактировать</Button>
                    <Button onClick={() => router.push(`/hotels/${record.id}/rooms`)}>Номерной фонд</Button>
                    <Button type="danger" onClick={() => onDelete(record)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [hotels, setHotels] = useState([]);

    const [fetchHotels, isLoading] = useFetching(async () => {
        const {data} = await HotelService.getAll();
        setHotels(data);
    });

    useEffect(() => {
        fetchHotels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [deleteHotel] = useFetching(async (id) => {
        await HotelService.delete(id);
        message.success('Отель удален');
        await fetchHotels();
    })

    const onDelete = async (hotel) => {
        Modal.confirm({
            title: 'Удалить отель?',
            icon: <ExclamationCircleOutlined />,
            content: `${hotel.titleRu} будет удален`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return deleteHotel(hotel.id);
            },
            onCancel() {},
        });
    }

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => router.push('/hotels/new')}
            >
                Добавить отель
            </Button>
            <Table columns={columns} dataSource={hotels} rowKey='id' loading={isLoading}/>
        </>
    );
};

export default Hotels;