import React, {useContext} from "react";

import {Button, Dropdown, Menu, PageHeader} from "antd";
import {AuthContext} from "../../context";
import Avatar from "antd/es/avatar/avatar";
import {UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import axios from "axios";


const MyHeader = () => {

    const {setIsAuth, user, setUser} = useContext(AuthContext);

    const logout = () => {
        delete axios.defaults.headers.common['Authorization'];
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        setIsAuth(false);
        setUser({});
    };

    const userMenu = (
        <Menu>
            <Menu.Item key={1}>
                <Button type="text"><Link to={`/users/${user.id}`}>Профиль</Link></Button>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key={2}>
                <Button type="text" onClick={logout}>Выйти</Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <PageHeader className="header-layout"
                    extra={[user.imageUrl ? <Avatar key={1} src={user.imageUrl}/> :
                        <Avatar key={1} icon={<UserOutlined/>}/>,
                        <Dropdown
                            key={2}
                            overlay={userMenu}><Button>{`${user.firstName} ${user.lastName}`}</Button>
                        </Dropdown>]}/>
    );
};

export default MyHeader;