import axios from "axios";

export default class ErrorLogsService {
    static async getAxessErrors(payload) {
        return axios.get('/axess-service/api/error-logs', {
            params: { ...payload }
        });

        // {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "orderId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "errorMessage": "string",
        //     "stackTrace": "string",
        //     "timestamp": "2024-09-27T11:11:14.253Z"
        // }
    }

    static getOrderErrors(payload) {
        return axios.get('/order-service/api/error-logs', {
            params: { ...payload }
        });

        // {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "timestamp": "2024-09-27T11:11:39.970Z",
        //     "orderId": "string",
        //     "orderNumber": "string",
        //     "errorMessage": "string",
        //     "stackTrace": "string"
        // }
    }

    static getRestaurantErrors(payload) {
        return axios.get('/restaurant-service/api/error-logs', {
            params: { ...payload }
        });

        // {
        //     "id": 0,
        //     "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "orderId": 0,
        //     "errorMessage": "string",
        //     "stackTrace": "string",
        //     "timestamp": "2024-09-27T11:12:03.538Z"
        // }
    }

    static getHotelErrors(payload) {
        return axios.get('/hotel-service/api/error-logs', {
            params: { ...payload }
        });

        // {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "reservedBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "reservationId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "errorMessage": "string",
        //     "stackTrace": "string",
        //     "timestamp": "2024-09-27T11:10:32.313Z"
        // }
    }
}