import axios from "axios";

export default class ProductService {

    static async getAll(page, size) {
        return await axios.get(`/merchant-service/admin/products?page=${page - 1}&size=${size}`);
    }

    static async getById(id) {
        return await axios.get(`/merchant-service/admin/products/${id}`);
    }

    static async create(data) {
        return await axios.post('/merchant-service/admin/products', data);
    }

    static async update(id, data) {
        return await axios.put(`/merchant-service/admin/products/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/merchant-service/admin/products/${id}`);
    }
}