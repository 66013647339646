import React, {useEffect, useState} from 'react';
import {Table, Button} from "antd";
import {useFetching} from "../../hooks/useFetching";
import ErrorLogsService from "../../API/ErrorLogsService";

const ErrorLogs = () => {
    const [columns, setColumns] = useState([]);

    const errorServiceList = [
        {
            name: 'axess',
            title: 'Axess'
        },
        {
            name: 'order',
            title: 'Заказы'
        },
        {
            name: 'restaurant',
            title: 'Рестораны'
        },
        {
            name: 'hotel',
            title: 'Отели'
        },
    ];

    const [errorService, setErrorService] = useState('axess');

    const [errorsList, setErrorsList] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [fetchErrorsList, isErrorsListLoading] = useFetching(async (pagination) => {
        const changedPagination = {
            page: pagination.current - 1,
            size: pagination.pageSize
        };

        let request = null;
        switch (errorService) {
            case 'axess':
                request = ErrorLogsService.getAxessErrors(changedPagination);
                break;
            case 'order':
                request = ErrorLogsService.getOrderErrors(changedPagination);
                break;
            case 'restaurant':
                request = ErrorLogsService.getRestaurantErrors(changedPagination);
                break;
            case 'hotel':
                request = ErrorLogsService.getHotelErrors(changedPagination);
                break;
            default:
                request = ErrorLogsService.getAxessErrors(changedPagination);
        }

        try {
            const {data} = await request;
            setErrorsList(data.content);
            setPagination({
                current: changedPagination.page + 1,
                pageSize: changedPagination.size,
                total: data.totalElements || 0
            });
        } catch (error) {
            console.log(error);
        }
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
        fetchErrorsList(pagination);
    };

    useEffect(() => {
        const axessLogColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Время',
                dataIndex: 'timestamp',
                key: 'timestamp'
            },
            {
                title: 'ID заказа',
                dataIndex: 'orderId',
                key: 'orderId'
            },
            {
                title: 'Текст ошибки',
                dataIndex: 'errorMessage',
                key: 'errorMessage'
            },
    
        ];
    
        const orderLogColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Время',
                dataIndex: 'timestamp',
                key: 'timestamp'
            },
            {
                title: 'ID заказа',
                dataIndex: 'orderId',
                key: 'orderId'
            },
            {
                title: 'Номер заказа',
                dataIndex: 'orderNumber',
                key: 'orderNumber'
            },
            {
                title: 'Текст ошибки',
                dataIndex: 'errorMessage',
                key: 'errorMessage'
            },
        ];
    
        const restaurantLogColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Время',
                dataIndex: 'timestamp',
                key: 'timestamp'
            },
            {
                title: 'ID пользователя',
                dataIndex: 'userId',
                key: 'userId'
            },
            {
                title: 'ID заказа',
                dataIndex: 'orderId',
                key: 'orderId'
            },
            {
                title: 'Текст ошибки',
                dataIndex: 'errorMessage',
                key: 'errorMessage'
            },
        ];
    
        const hotelLogColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Время',
                dataIndex: 'timestamp',
                key: 'timestamp'
            },
            {
                title: 'ID пользователя',
                dataIndex: 'reservedBy',
                key: 'reservedBy'
            },
            {
                title: 'ID заказа',
                dataIndex: 'reservationId',
                key: 'reservationId'
            },
            {
                title: 'Текст ошибки',
                dataIndex: 'errorMessage',
                key: 'errorMessage'
            },
        ];

        switch (errorService) {
            case 'axess':
                setColumns(axessLogColumns);
                break;
            case 'order':
                setColumns(orderLogColumns);
                break;
            case 'restaurant':
                setColumns(restaurantLogColumns);
                break;
            case 'hotel':
                setColumns(hotelLogColumns);
                break;
            default:
                setColumns(axessLogColumns);
        }
    }, [errorService]);


    useEffect(() => {
        fetchErrorsList(pagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorService]);

    return (
        <>
            {
                errorServiceList.map(service => 
                    <Button 
                        key={service.name} 
                        onClick={() => setErrorService(service.name)}
                        style={{marginRight: '10px', marginBottom: '20px'}}
                        type={errorService === service.name ? 'primary' : 'default'}>
                        {service.title}
                    </Button>
                )
            }
            <Table 
                columns={columns} 
                dataSource={errorsList} 
                rowKey='id' 
                loading={isErrorsListLoading}
                expandable={{
                    expandedRowRender: (record) => 
                    <p style={{ margin: 0, whiteSpace: 'pre-wrap'}}>
                        Stack Trace:
                        <br/>
                        {record.stackTrace}
                    </p>
                }}
                pagination={pagination}
                onChange={handleTableChange}/>
        </>
    );
};

export default ErrorLogs;