import React, {useEffect, useState} from 'react';
import Modal from "antd/es/modal/Modal";
import {Button, Form, Input, InputNumber, Switch} from "antd";
import {useFetching} from "../../hooks/useFetching";
import OnboardingService from "../../API/OnboardingService";
import UploadImage from "../UI/UploadImage";

const CreateOrUpdateLiveModal = ({model, visible, onClose}) => {

    const [form] = Form.useForm();

    const fields = [
        {
            name: 'titleRu',
            placeholder: 'Наименование на рус.',
            required: true
        },
        {
            name: 'titleKk',
            placeholder: 'Наименование на каз.',
            required: true
        },
        {
            name: 'titleEn',
            placeholder: 'Наименование на анг.',
            required: true
        },
        {
            name: 'textRu',
            placeholder: 'Текст на рус.',
            required: false
        },
        {
            name: 'textKk',
            placeholder: 'Текст на каз.',
            required: false
        },
        {
            name: 'textEn',
            placeholder: 'Текст на анг.',
            required: false
        },
    ];

    const [createOrUpdateOnboarding, isLoading] = useFetching(async (data) => {
        if (model) {
            await OnboardingService.update(model.id, data);
        } else {
            await OnboardingService.create(data);
        }
        form.resetFields();
        onClose();
    })

    const handleOk = async (values) => {
        await createOrUpdateOnboarding(values);
    };

    const handleCancel = () => {
        onClose(false);
        form.resetFields();
    }

    useEffect(() => {
        if (model) {
            form.setFieldsValue({
                ...model
            });
        } else {
            form.resetFields();
        }
    }, [model])

    const setImageUrl = (url) => {
        form.setFieldsValue({imageUrl: url});
    };

    const [isUploading, setIsUploading] = useState(false);

    return (
        <Modal
            title={model ? 'Редактировать онбординг' : 'Создать онбординг'}
            onCancel={handleCancel}
            footer={[
                <Button type="primary" disabled={isUploading} form="onboardingForm" key="submit" htmlType="submit">
                    Сохранить
                </Button>
            ]}
            confirmLoading={isLoading}
            visible={visible}>
            <Form
                form={form}
                id="onboardingForm"
                autoComplete="off"
                onFinish={handleOk}
            >
                {
                    fields.map(field => {
                        let rules = field.required ? [{required: true, message: 'Поле не может быть пустым!'}] : [];
                        if (field.rules) {
                            rules = [...rules, ...field.rules];
                        }
                        return (
                            <Form.Item
                                key={field.name}
                                name={field.name}
                                rules={rules}
                            >
                                <Input placeholder={field.placeholder}/>
                            </Form.Item>
                        );
                    })
                }
                <Form.Item key="imageUrl" name="imageUrl">
                    <Input placeholder="Ссылка на изображение"/>
                </Form.Item>
                <Form.Item>
                    <UploadImage setImageUrl={setImageUrl} setIsUploading={setIsUploading}/>
                </Form.Item>
                <Form.Item label="Показывать?" name="enabled">
                    <Switch />
                </Form.Item>
                <Form.Item label="Порядковый номер" name="orderNum">
                    <InputNumber/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateOrUpdateLiveModal;