import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import TicketTypeService from "../../API/TicketTypeService";
import {Link, useHistory, useParams} from "react-router-dom";
import {Descriptions, Image, PageHeader, Space, Table, Typography} from "antd";

const TicketTypeIdPage = () => {

    const params = useParams();
    const router = useHistory();

    const [ticketType, setTicketType] = useState({});

    const [fetchTicketType, isLoading] = useFetching(async (id) => {
        const {data} = await TicketTypeService.get(id);
        setTicketType(data);
    });

    const tariffHeaders = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Наименование',
            dataIndex: 'nameRu',
            key: 'nameRu'
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Тариф из Axess',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/axess/meta-tariffs/${record.axessMetaTariffId}`}>{record.axessMetaTariffId}</Link>
                </Space>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
    ];


    useEffect(() => {
        fetchTicketType(params.id);
    }, []);

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Информация о типе билета"
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="Наименование">{ticketType.nameRu}</Descriptions.Item>
                    <Descriptions.Item label="Изображение">
                        <Image
                            width={200}
                            src={ticketType.imageUrl}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Категория">{ticketType.categoryName ? ticketType.categoryName.ru : ''}</Descriptions.Item>
                    <Descriptions.Item label="Код">{ticketType.code}</Descriptions.Item>
                    <Descriptions.Item label="Включен">{ticketType.enabled ? 'Да' : 'Нет'}</Descriptions.Item>
                    <Descriptions.Item label="Требуется дата">{ticketType.enabled ? 'Да' : 'Нет'}</Descriptions.Item>
                    <Descriptions.Item label="Требуется skipass">{ticketType.enabled ? 'Да' : 'Нет'}</Descriptions.Item>
                    <Descriptions.Item
                        label="Кол-во тарифов">{ticketType.tariffs ? ticketType.tariffs.length : 0}</Descriptions.Item>
                    <Descriptions.Item label="Дата создания">{ticketType.createdDate}</Descriptions.Item>
                    <Descriptions.Item label="Дата обновления">{ticketType.createdDate}</Descriptions.Item>
                </Descriptions>

                <Typography.Title level={3} style={{marginTop: 32}}>Тарифы по дням</Typography.Title>
                <Table columns={tariffHeaders} dataSource={ticketType.tariffs} rowKey='id' loading={isLoading}/>
            </div>
        </div>
    );
};

export default TicketTypeIdPage;