import axios from "axios";

export default class RestaurantService {
    static async getAll() {
        return await axios.get('/restaurant-service/admin/restaurants');
    }

    static async getById(id) {
        return await axios.get(`/restaurant-service/admin/restaurants/${id}`);
    }

    static async create(data) {
        return await axios.post('/restaurant-service/admin/restaurants', data);
    }

    static async update(id, data) {
        return await axios.put(`/restaurant-service/admin/restaurants/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/restaurant-service/admin/restaurants/${id}`);
    }
}