import axios from "axios";

export default class FeedbackService {

    static async getNewFeedbacks(page, size) {
        return await axios.get(`/feedback-service/admin/feedback/new?page=${page-1}&pageSize=${size}`);
    }

    static async getApprovedFeedbacks(page, size) {
        return await axios.get(`/feedback-service/admin/feedback/approved?page=${page-1}&pageSize=${size}`);
    }


    static async approve(id) {
        return await axios.post(`/feedback-service/admin/feedback/approve/${id}`);
    }

    static async reject(id) {
        return await axios.post(`/feedback-service/admin/feedback/reject/${id}`);
    }
}
