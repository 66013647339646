import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Button, Col, Form, Input, InputNumber, Row, Switch, Typography, message, Spin, PageHeader} from "antd";
import {useFetching} from "../../hooks/useFetching";
import HotelService from "../../API/HotelService";
import UploadImage from "../../components/UI/UploadImage";

const HotelIdPage = () => {

    const params = useParams();
    const router = useHistory();
    const [form] = Form.useForm();

    const [saveHotel, isSaving, saveErrors] = useFetching(async (values) => {
        let response;
        if (params.id === 'new') {
            response = await HotelService.create(values);
        } else {
            response = await HotelService.update(params.id, values);
        }

        message.success('Отель сохранен');
        if (params.id === 'new') {
            router.push(`/hotels/${response.data.id}`)
        }
    });

    const [fetchHotel, isLoading, errors] = useFetching(async (id) => {
        const {data} = await HotelService.getById(id);
        form.setFieldsValue({...data});
    });

    const onSave = async (values) => {
        await saveHotel(values);
    };

    useEffect(() => {
        if (params.id !== 'new') {
            fetchHotel(params.id);
        }
    }, [params])

    useEffect(() => {
        if (errors) {
            message.error(errors);
        }
        if (saveErrors) {
            message.error(saveErrors);
        }
    }, [errors, saveErrors]);

    const setImageUrl = (url) => {
        form.setFieldsValue({imageUrl: url});
    };

    const [isUploading, setIsUploading] = useState(false);

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.push('/hotels')}
                title="Описание отеля"
                extra={[
                    params.id !== 'new' && <Button onClick={() => router.push(`/hotels/${params.id}/rooms`)}>
                        Номерной фонд
                    </Button>,
                    <Button type="primary" disabled={isUploading} form="hotelForm" key="submit" htmlType="submit">
                        Сохранить
                    </Button>
                ]}
            />
            <Spin spinning={isLoading || isSaving}>
                <Form
                    id="hotelForm"
                    form={form}
                    onFinish={onSave}
                    layout="vertical"
                    style={{background: '#fff', padding: 16}}
                >
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Наименование на рус." name="titleRu" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на каз." name="titleKk" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на анг." name="titleEn" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ссылка на изображение"
                                name="imageUrl"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item>
                                <UploadImage setImageUrl={setImageUrl} setIsUploading={setIsUploading}/>
                            </Form.Item>
                            <Form.Item
                                label="Ссылка на instagram"
                                name="instagramUrl"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Ссылка на booking"
                                name="bookingUrl"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Время работы" name="workingHours">
                                <Input placeholder="Например: 10:00 - 16:00"/>
                            </Form.Item>
                            <Form.Item label="Номер телефона" name="phoneNumber">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Показывать отель" name="enabled" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item label="Минимальная стоимость" name="minPrice">
                                <InputNumber/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Рейтинг" name="rating">
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item label="Количество отзывов" name="ratingCount">
                                <InputNumber/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Описание на рус." name="descriptionRu">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Описание на каз." name="descriptionKk">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Описание на анг." name="descriptionEn">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default HotelIdPage;