import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import PaymentService from "../../API/PaymentService";
import {Space, Table} from "antd";
import {Link} from "react-router-dom";

const Payments = () => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId'
        },
        {
            title: 'Заказ',
            key: 'invoiceId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/orders/${record.invoiceId}`}>{record.invoiceId}</Link>
                </Space>
            )
        },
        {
            title: 'Событие',
            dataIndex: 'eventType',
            key: 'eventType'
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Время',
            dataIndex: 'dateTime',
            key: 'dateTime'
        },
        {
            title: 'Первые 6 цифры',
            dataIndex: 'cardFirstSix',
            key: 'cardFirstSix'
        },
        {
            title: 'Последние 4 цифры',
            dataIndex: 'cardLastFour',
            key: 'cardLastFour'
        },
        {
            title: 'Тип карты',
            dataIndex: 'cardType',
            key: 'cardType'
        }
    ];

    const [invoices, setInvoices] = useState([]);

    const [fetchInvoices, isLoading] = useFetching(async (page) => {
       const {data} = await PaymentService.getAll(null, page);
       setInvoices(data.content);
    });

    useEffect(() => {
        fetchInvoices(0)
    }, [])

    return (
        <Table columns={columns} dataSource={invoices} rowKey='id' loading={isLoading}/>
    );
};

export default Payments;