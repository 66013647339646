import axios from "axios";

export default class ProductCategoryService {
    static async getAll() {
        return await axios.get('/merchant-service/admin/categories');
    }

    static async getById(id) {
        return await axios.get(`/merchant-service/admin/categories/${id}`);
    }

    static async create(data) {
        return await axios.post('/merchant-service/admin/categories', data);
    }

    static async update(id, data) {
        return await axios.put(`/merchant-service/admin/categories/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/merchant-service/admin/categories/${id}`);
    }
}