import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import OrderService from "../../API/OrderService";
import OrderList from "../../components/orders/OrderList";
import UserService from "../../API/UserService";
import {AutoComplete, Col, Divider, Row} from "antd";
import Search from "antd/lib/input/Search";

const Orders = () => {

    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 50,
    });

    const [orderNum, setOrderNum] = useState(null);
    const onSearchByOrderNum = (val) => setOrderNum(val);

    const [fetchOrders, isLoading] = useFetching(async (page, size, userId, orderNum) => {
        const {data} = await OrderService.getAll(page, size, userId, orderNum);
        const userResponse = await UserService.getAllByIds([...new Set(data.items.map(order => order.userId))]);

        const users = Object.assign({}, ...userResponse.data.map((it) => ({[it.id]: it})));

        data.items.forEach(order => {
            const user = users[order.userId];
            order.userName = user && (user.lastName || user.firstName)
                ? `${user.lastName} ${user.firstName}` : (user ? user.id : 'USER DELETED');
        });

        setOrders(data.items);
        setPagination({
            current: data.page + 1,
            pageSize: pagination.pageSize,
            total: data.totalItems
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchOrders(pagination.current, pagination.pageSize, null, null);
    }

    const [userOptions, setUserOptions] = useState([]);
    const [fetchUsers, isUsersLoading] = useFetching(async (searchTerm) => {
        const {data} = await UserService.getAll(1, 20, searchTerm);
        setUserOptions(data.content.map(user => {
            return {
                value: user.id,
                label: `${user.lastName} ${user.firstName}, phone: ${user.phone}, email: ${user.email}`
            }
        }));
    });

    const [userId, setUserId] = useState(null);
    const handleUserSearch = async (searchTerm) => {
        if (!!searchTerm && searchTerm.length > 2) {
            await fetchUsers(searchTerm);
        }
    };

    useEffect(() => {
        fetchOrders(pagination.current, pagination.pageSize, userId, orderNum);
    }, []);

    useEffect(() => {
        fetchOrders(pagination.current, pagination.pageSize, userId, orderNum);
    }, [userId, orderNum]);

    return (
        <div>
            <Divider orientation="left">Поиск по заказам</Divider>

            <Row gutter={16}>
                <Col span={4}>
                    <Search size="large" placeholder="Номер заказа" onSearch={onSearchByOrderNum}/>
                </Col>
                <Col span={4}>
                    <Search size="large" placeholder="Номер WTP" disabled/>
                </Col>
                <Col span={16}>
                    <AutoComplete
                        allowClear={true}
                        options={userOptions}
                        loading={isUsersLoading}
                        style={{width: '100%'}}
                        size="large"
                        dropdownMatchSelectWidth={900}
                        onSelect={(value) => setUserId(value)}
                        onClear={() => setUserId(null)}
                        onSearch={handleUserSearch}
                        placeholder='Поиск по ФИО, номеру тел. или email'
                    />
                </Col>
            </Row>
            <Divider/>
            <OrderList orders={orders}
                       isLoading={isLoading}
                       pagination={pagination}
                       handleTableChange={handleTableChange}
            />
        </div>
    );
};

export default Orders;