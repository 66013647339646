import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Button, Col, Form, Input, message, PageHeader, Row, Spin, Switch} from "antd";
import {useFetching} from "../../hooks/useFetching";
import UploadImage from "../../components/UI/UploadImage";
import ProductCategoryService from "../../API/ProductCategoryService";

const ProductCategoryIdPage = () => {
    const params = useParams();
    const router = useHistory();
    const [form] = Form.useForm();

    const [saveCategory, isSaving, saveErrors] = useFetching(async (values) => {
        let response;
        if (params.id === 'new') {
            response = await ProductCategoryService.create(values);
        } else {
            response = await ProductCategoryService.update(params.id, values);
        }

        message.success('Категория сохранена');
        if (params.id === 'new') {
            router.push(`/merchant/categories/${response.data.id}`)
        }
    });

    const onSave = async (values) => {
        await saveCategory(values);
    };

    const [fetchCategory, isLoading] = useFetching(async (id) => {
        const {data} = await ProductCategoryService.getById(id);
        form.setFieldsValue({...data});
    });

    useEffect(() => {
        if (params.id !== 'new') {
            fetchCategory(params.id);
        }
    }, []);

    const setImageUrl = (url) => {
        form.setFieldsValue({imageUrl: url});
    };

    const [isUploading, setIsUploading] = useState(false);

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Категория товара"
            />
            <Spin spinning={isLoading || isSaving}>
                <Form
                    form={form}
                    onFinish={onSave}
                    layout="vertical"
                    style={{background: '#fff', padding: 16}}
                >
                    <Form.Item>
                        <Button type="primary" disabled={isUploading} htmlType="submit">Сохранить</Button>
                    </Form.Item>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Наименование на рус." name="nameRu" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на каз." name="nameKk" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на анг." name="nameEn" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ссылка на изображение"
                                name="imageUrl"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item>
                                <UploadImage setImageUrl={setImageUrl} setIsUploading={setIsUploading}/>
                            </Form.Item>
                            <Form.Item label="Показывать категорию" name="enabled" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item label="ID родительской категории" name="parentId">
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default ProductCategoryIdPage;