import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import OrderService from "../../API/OrderService";
import {Button, Descriptions, Image, PageHeader, Space, Table, Tag, Typography} from "antd";
import UserService from "../../API/UserService";
import {CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";

const OrderIdPage = () => {

    const params = useParams();
    const router = useHistory();
    const [order, setOrder] = useState({});
    const [payments, setPayments] = useState([]);
    const [user, setUser] = useState({});
    const [receipt, setReceipt] = useState({});

    const [fetchOrder, isLoading] = useFetching(async (id) => {
        const {data} = await OrderService.getById(id);
        const userResponse = await UserService.getByUserId(data.userId);
        setOrder(data);
        setUser(userResponse.data);

        try {
            const receiptResponse = await OrderService.getReceiptByInvoiceId(data.orderNum);
            setReceipt(receiptResponse.data);
        } catch (e) {
            console.log(e);
        }
        setPayments(data.payments.map((payment) => payment.data));
    });

    useEffect(() => {
        fetchOrder(params.id);
    }, []);

    const columns = [
        {
            title: 'Axess Meta tariff id',
            dataIndex: 'axesMetaTariffId',
            key: 'axesMetaTariffId'
        },
        {
            title: 'Axess ticket id',
            dataIndex: 'axessTicketId',
            key: 'axessTicketId'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Entity type',
            dataIndex: 'entityType',
            key: 'entityType'
        },
        {
            title: 'Entity ID',
            dataIndex: 'entityId',
            key: 'entityId'
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin/>} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'RESERVED') {
                    return <Tag icon={<SyncOutlined spin/>} color="success">
                        В резерве
                    </Tag>
                } else if (record.status === 'COMPLETED') {
                    return <Tag icon={<CheckCircleOutlined/>} color="success">
                        Завершен
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Отменен
                    </Tag>
                }
            }
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'К оплате',
            dataIndex: 'toPay',
            key: 'toPay'
        },
        {
            title: 'skiPass',
            dataIndex: 'skiPass',
            key: 'skiPass'
        },
        {
            title: 'PickupBox?',
            dataIndex: 'isPickupBox',
            key: 'isPickupBox'
        },
        {
            title: 'qrData',
            dataIndex: 'qrData',
            key: 'qrData'
        },
        {
            title: 'Promocode',
            dataIndex: 'promocode',
            key: 'promocode'
        },
    ];

    const receiptColumns = [
        {
            title: 'TNG ID',
            dataIndex: 'tngId',
            key: 'tngId'
        },
        {
            title: 'itemType',
            dataIndex: 'itemType',
            key: 'itemType'
        },
        {
            title: 'Наименование',
            dataIndex: 'label',
            key: 'label'
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Кол-во',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Сумма к оплате',
            dataIndex: 'amount',
            key: 'amount'
        },
    ];

    const paymentColumns = [
        {
            title: 'Способ оплаты',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Тип карты',
            dataIndex: 'cardType',
            key: 'cardType'
        },
        {
            title: 'Последние 4 цифры карты',
            dataIndex: 'cardLastFour',
            key: 'cardLastFour'
        },
        {
            title: 'AIRBA credit code',
            dataIndex: 'creditCode',
            key: 'creditCode'
        },
        {
            title: 'paymentEventId',
            dataIndex: 'paymentEventId',
            key: 'paymentEventId'
        },
    ];

    const renderStatus = (status) => {
        if (status === 'CREATED') {
            return <Tag icon={<SyncOutlined spin />} color="processing">
                Создан
            </Tag>
        } else if (status === 'FINALIZED') {
            return <Tag icon={<SyncOutlined spin />} color="processing">
                Ожидание оплаты
            </Tag>
        } else if (status === 'PAID') {
            return <Tag icon={<CheckCircleOutlined />} color="success">
                Оплачен
            </Tag>
        } else if (status === 'FAILED') {
            return <Tag icon={<CloseCircleOutlined/>} color="error">
                Ошибка платежа
            </Tag>
        } else if (status === 'CANCELLED') {
            return <Tag icon={<MinusCircleOutlined/>} color="default">
                Отменен
            </Tag>
        }
    }

    return (
        <div style={{background: '#fff', margin: "auto"}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title={`Информация о заказе #${order.orderNum}`}
                // extra={<Button type="primary">Изменить пароль</Button>}
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="Фото"><Image
                        width={150}
                        src={user.imageUrl}
                    /></Descriptions.Item>
                    <Descriptions.Item label="ФИО">
                        <Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>
                    <Descriptions.Item label="Статус">{renderStatus(order.status)}</Descriptions.Item>
                    <Descriptions.Item label="Сумма заказа">{order.totalAmount}</Descriptions.Item>
                    <Descriptions.Item label="Сумма к оплате">{order.amountToPay}</Descriptions.Item>
                    <Descriptions.Item label="Бонусы">{order.bonusAmount}</Descriptions.Item>
                    <Descriptions.Item label="Депозит">{order.depositAmount}</Descriptions.Item>
                    <Descriptions.Item label="Ваучер">{order.voucherAmount}</Descriptions.Item>
                    <Descriptions.Item label="Создан">{order.createdAt}</Descriptions.Item>
                    <Descriptions.Item label="Обновлен">{order.updatedAt}</Descriptions.Item>
                </Descriptions>
            </div>
            <div style={{padding: 32}}>
                <Typography.Title level={4} style={{marginTop: 32}}>Платежи</Typography.Title>
                <Table columns={paymentColumns}
                       dataSource={payments}
                       rowKey='id'
                       loading={isLoading}
                       pagination={false}
                />
                <Typography.Title level={4} style={{marginTop: 32}}>Квитанция</Typography.Title>
                <Table columns={receiptColumns}
                       dataSource={receipt.items}
                       rowKey='id'
                       loading={isLoading}
                       pagination={false}
                       size='small'
                />
                <Typography.Title level={4} style={{marginTop: 32}}>Позиции</Typography.Title>
                <Table columns={columns}
                       dataSource={order.orderItems}
                       rowKey='id'
                       loading={isLoading}
                       pagination={false}
                       size='small'
                />
            </div>
        </div>
    );
};

export default OrderIdPage;