import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Button, Col, Form, Input, InputNumber, message, PageHeader, Row, Spin, Switch} from "antd";
import {useFetching} from "../../hooks/useFetching";
import UploadImage from "../../components/UI/UploadImage";
import ProductService from "../../API/ProductService";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const ProductIdPage = () => {
    const params = useParams();
    const router = useHistory();
    const [form] = Form.useForm();

    const [saveProduct, isSaving, saveErrors] = useFetching(async (values) => {
        console.log(values);
        let response;
        if (params.id === 'new') {
            response = await ProductService.create(values);
        } else {
            response = await ProductService.update(params.id, values);
        }

        message.success('Товар сохранен');
        if (params.id === 'new') {
            router.push(`/merchant/products/${response.data.id}`)
        }
    });

    const onSave = async (values) => {
        await saveProduct(values);
    };

    const [fetchProduct, isLoading] = useFetching(async (id) => {
        const {data} = await ProductService.getById(id);
        form.setFieldsValue({...data});
    });

    useEffect(() => {
        if (params.id !== 'new') {
            fetchProduct(params.id);
        }
    }, []);

    const setImageUrl = (url) => {
        form.setFieldsValue({imageUrl: url});
    };

    const [isUploading, setIsUploading] = useState(false);

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Товар"
            />
            <Spin spinning={isLoading || isSaving}>
                <Form
                    form={form}
                    onFinish={onSave}
                    layout="vertical"
                    style={{background: '#fff', padding: 16}}
                >
                    <Form.Item>
                        <Button type="primary" disabled={isUploading} htmlType="submit">Сохранить</Button>
                    </Form.Item>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Артикул" name="article">
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на рус." name="nameRu" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на каз." name="nameKk" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на анг." name="nameEn" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Показывать товар" name="enabled" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item label="ID категории" name="categoryId" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Цена" name="price" rules={[{required: true}]}>
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item label="Старая цена" name="oldPrice">
                                <InputNumber/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ссылка на изображение"
                                name="imageUrl"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item>
                                <UploadImage setImageUrl={setImageUrl} setIsUploading={setIsUploading}/>
                            </Form.Item>
                            <Form.List name="sliderImageUrls">
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={index === 0 ? 'Ссылки на изображении для слайдера' : ''}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Укажите ссылку или удалите это поле",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Ссылка на изображение" style={{width: '60%'}}/>
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <UploadImage title="Загрузить изображение для слайдера" setImageUrl={add} setIsUploading={setIsUploading}/>
                                            <Form.ErrorList errors={errors}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.List name="sizes">
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={index === 0 ? 'Размеры' : ''}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Укажите размер или удалите это поле",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Размер" style={{width: '60%'}}/>
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{width: '60%'}}
                                                icon={<PlusOutlined/>}
                                            >
                                                Добавить размер
                                            </Button>
                                            <Form.ErrorList errors={errors}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Form.Item label="Характеристики на рус." name="specificationRu">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Характеристики на каз." name="specificationKk">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Характеристики на анг." name="specificationEn">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Описание на рус." name="descriptionRu">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Описание на каз." name="descriptionKk">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item label="Описание на анг." name="descriptionEn">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default ProductIdPage;
