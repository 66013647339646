import React, {useEffect, useState} from 'react';
import {UserOutlined} from "@ant-design/icons";
import {Row, Col, Statistic, Table, Card, Divider} from "antd";
import {useFetching} from "../hooks/useFetching";
import UserService from '../API/UserService';
import OrderService from '../API/OrderService';
import {Column, Line} from "@ant-design/charts";

const Dashboard = () => {

    const [order, setOrder] = useState({});
    const [todayNewUsers, setTodayNewUsers] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [dailyNewUsers, setDailyNewUsers] = useState([]);
    const [orderSales, setOrderSales] = useState([]);


    const [fetchCounts, isLoading] = useFetching(async () => {
        const orderData = await OrderService.count();
        const orderSalesData = await OrderService.grouped()
        setOrder(orderData.data);
        setOrderSales(orderSalesData.data);
    });

    const [fetchUsersData, isUsersDataLoading] = useFetching(async () => {
        const response = await UserService.getUsersStatistics();
        setTotalUsers(response.data.totalUsers);
        setDailyNewUsers(response.data.dailyNewUsers);
        if (response.data && response.data.dailyNewUsers.length > 0) {
            setTodayNewUsers(response.data.dailyNewUsers[response.data.dailyNewUsers.length - 1].cnt);
        }
    });

    useEffect(() => {
        fetchCounts();
        fetchUsersData();
    }, []);

    const userChartConfig = {
        data: dailyNewUsers,
        xField: 'date',
        yField: 'cnt',
        conversionTag: {},
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    const orderSalesChartConfig = {
        data: orderSales,
        xField: 'date',
        yField: 'sum',
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    return (
        <div>
            <Divider orientation="left">Статистика по заказам</Divider>
            <Row gutter={32}>
                <Col span={6}>
                    <Card>
                        <Statistic isLoading={isLoading} title="Оплаченные заказы" value={order.paid}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic isLoading={isLoading} title="Отменённые заказы" value={order.cancelled}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic isLoading={isLoading} title="Заказы в обработке" value={order.pending}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic isLoading={isLoading} title="Броней в отели" value={order.bookings}/>
                    </Card>
                </Col>
            </Row>

            <Divider orientation="left" style={{marginTop: '32px'}}>Оплачено картой за заказ</Divider>
            <Row>
                <Col span={24}>
                    <Card>
                        <Column {...orderSalesChartConfig} isLoading={isLoading}/>
                    </Card>
                </Col>
            </Row>

            <Divider orientation="left" style={{marginTop: '32px'}}>Статистика по пользователям</Divider>
            <Row gutter={32}>
                <Col span={6}>
                    <Card>
                        <Statistic isLoading={isUsersDataLoading} title="Общее количество" value={totalUsers}
                                   prefix={<UserOutlined/>}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic isLoading={isUsersDataLoading} title="Новых пользователей сегодня"
                                   value={todayNewUsers}
                                   prefix={<UserOutlined/>}/>
                    </Card>
                </Col>
            </Row>

            <Divider orientation="left" style={{marginTop: '32px'}}>Регистрация пользователей за последние 10
                дней</Divider>
            <Row>
                <Col span={24}>
                    <Card>
                        <Column {...userChartConfig} isLoading={isUsersDataLoading}/>
                    </Card>
                </Col>
            </Row>

        </div>
    );
};

export default Dashboard;