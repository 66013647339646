import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import RestaurantReservationService from "../../API/RestaurantReservationService";
import RestaurantReservationList from "../../components/restaurant/RestaurantReservationList";

const RestaurantsReservation = () => {



    const [reservations, setReservations] = useState([]);

    const [fetchReservations, isLoading] = useFetching(async () => {
        const {data} = await RestaurantReservationService.getAll(null, 0);
        setReservations(data.content);
    });

    useEffect(() => {
        fetchReservations();
    }, []);

    return (
        <>
            <RestaurantReservationList reservations={reservations} isLoading={isLoading} />
        </>
    );
};

export default RestaurantsReservation;