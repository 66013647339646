import axios from "axios";

export default class BonusService {

    static async addBalance(userId, data) {
        return await axios.post(`/user-profile-service/admin/users/${userId}/add-balance`, data);
    }

    static async withdrawBalance(userId, data) {
        return await axios.post(`/user-profile-service/admin/users/${userId}/withdraw-balance`, data);
    }
}