import axios from "axios";

export default class RentItemService {

    static async getAll() {
        return await axios.get('/rent-service/admin/rent-items');
    }

    static async getById(id) {
        return await axios.get(`/rent-service/admin/rent-items/${id}`);
    }

    static async create(data) {
        return await axios.post('/rent-service/admin/rent-items', data);
    }

    static async update(id, data) {
        return await axios.put(`/rent-service/admin/rent-items/${id}`, data);
    }

    static async delete(id) {
        return await axios.delete(`/rent-service/admin/rent-items/${id}`);
    }
}