import axios from "axios";

export default class AxessService {
    static async getAllMetaTariffs() {
        return await axios.get('/axess-service/admin/meta-tariffs');
    }

    static async getMetaTariff(id) {
        return await axios.get(`/axess-service/admin/meta-tariffs/${id}`);
    }

    static async getTariffsByMetaId(id) {
        return await axios.get(`/axess-service/admin/meta-tariffs/${id}/tariffs`);
    }

    static async updateAll() {
        return await axios.get('/axess-service/admin/meta-tariffs/updateAll');
    }

    static async updateTariffs(id) {
        return await axios.get(`/axess-service/admin/meta-tariffs/${id}/update-tariffs`);
    }
}