import axios from "axios";

export default class RestaurantReservationService {
    static async getAll(userId, page) {
        const params = {
            page
        };
        if (userId) {
            params.userId = userId;
        }
        return await axios.get('/restaurant-service/admin/reservation', {params});
    }
}