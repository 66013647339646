import React, {useEffect, useRef} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {Modal} from "antd";

const VideoModal = ({setVisible, visible, videoUrl}) => {

    const playerRef = useRef();

    const closeVideoModal = () => {
        playerRef.current.pause();
        setVisible(false);
    }

    useEffect(() => {
        if (visible) {
            playerRef.current.play();
        }
    }, [visible])

    return (
        <Modal
            title="Basic Modal"
            onOk={closeVideoModal}
            onCancel={closeVideoModal}
            visible={visible}>
            <ReactHlsPlayer
                playerRef={playerRef}
                src={videoUrl}
                autoPlay={false}
                controls={true}
                width="100%"
                height="auto"
            />
        </Modal>
    );
};

export default VideoModal;