import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import {useHistory, useParams} from "react-router-dom";
import RentItemService from "../../API/RentItemService";
import {Button, Col, Form, Input, InputNumber, message, PageHeader, Row, Select, Spin, Switch} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const RentItemIdPage = () => {

    const router = useHistory();
    const params = useParams();
    const [form] = Form.useForm();

    const [saveItem, isSaving, saveErrors] = useFetching(async (values) => {
        let response;
        if (params.id === 'new') {
            response = await RentItemService.create(values);
        } else {
            response = await RentItemService.update(params.id, values);
        }

        message.success('Инвентарь сохранен');
        if (params.id === 'new') {
            router.push(`/rent/items/${response.data.id}`)
        }
    });

    const [fetchItem, isLoading] = useFetching(async (id) => {
       const {data} = await RentItemService.getById(id);
       form.setFieldsValue({...data});
    });

    const onSave = async (values) => {
        await saveItem(values);
    };

    useEffect(() => {
        if (params.id !== 'new') {
            fetchItem(params.id);
        }
    }, [params]);

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.push('/rent/items')}
                title="Описание инвентаря"
                extra={[
                    <Button type="primary" form="hotelForm" key="submit" htmlType="submit">
                        Сохранить
                    </Button>
                ]}
            />
            <Spin spinning={isLoading || isSaving}>
                <Form
                    id="hotelForm"
                    form={form}
                    onFinish={onSave}
                    layout="vertical"
                    style={{background: '#fff', padding: 16}}
                >
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Наименование на рус." name="nameRu" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на каз." name="nameKk" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label="Наименование на анг." name="nameEn" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Тип" name="type" rules={[{required: true}]}>
                                <Select disabled={params.id !== 'new'}>
                                    <Select.Option value="DAILY">Дневной</Select.Option>
                                    <Select.Option value="NIGHTLY">Ночной</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Показывать инвентарь" name="enabled" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item label="Цена в будние дни" name="workingDayPrice" rules={[{required: true}]}>
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item label="Цена в выходнын дни" name="holidayPrice" rules={[{required: true}]}>
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item label="Доступное кол-во" name="quantity" rules={[{required: true}]}>
                                <InputNumber/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.List name="clothesSizes">
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={index === 0 ? 'Размеры одежды' : ''}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Укажите размер или удалите это поле",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Размер" style={{width: '60%'}}/>
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{width: '60%'}}
                                                icon={<PlusOutlined/>}
                                            >
                                                Добавить размер
                                            </Button>
                                            <Form.ErrorList errors={errors}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col span={6}>
                            <Form.List name="shoesSizes">
                                {(fields, {add, remove}, {errors}) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={index === 0 ? 'Размеры обуви' : ''}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Укажите размер или удалите это поле",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Размер" style={{width: '60%'}}/>
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{width: '60%'}}
                                                icon={<PlusOutlined/>}
                                            >
                                                Добавить размер
                                            </Button>
                                            <Form.ErrorList errors={errors}/>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default RentItemIdPage;