import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import HotelBookService from "../../API/HotelBookService";
import HotelBookingList from "../../components/hotel/HotelBookingList";

const HotelBooking = () => {

    const [bookingList, setBookingList] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const [fetchBookings, isLoading] = useFetching(async ({current, pageSize}) => {
        const {data} = await HotelBookService.getAll({
            page: current - 1,
            size: pageSize,
            locale: 'ru',
            sort: '-createdAt'
        });
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
        setBookingList(data.content);
    });

    const handleTableChange = async (pagination) => {
        await fetchBookings(pagination);
    }

    useEffect(() => {
        fetchBookings(pagination);
    }, []);

    return (
        <>
            <HotelBookingList
                bookingList={bookingList}
                pagination={pagination}
                rowKey='id'
                isLoading={isLoading}
                handleTableChange={handleTableChange}
            />
        </>
    );
};

export default HotelBooking;