import axios from "axios";

export default class TicketTypeService {
    static async getAll(page, categoryId) {
        const params = {page};
        if (categoryId) {
            params.categoryId = categoryId;
        }
        return await axios.get('/ticket-service/admin/ticket-types', {params});
    }

    static async get(id) {
        return await axios.get(`/ticket-service/admin/ticket-types/${id}`);
    }
}