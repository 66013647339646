import axios from "axios";

export default class RentItemOrderService {

    static async getAll(page, size) {
        return await axios.get(`/rent-service/admin/orders?page=${page - 1}&size=${size}`);
    }

    static async updateStatus(id, status) {
        return await axios.put(`/rent-service/admin/orders/${id}/status`, {status});
    }
}