import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import TicketCategoryService from "../../API/TicketCategoryService";
import {Table} from "antd";

const TicketCategories = () => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Наименование',
            dataIndex: 'nameRu',
            key: 'nameRu'
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        }
    ];

    const [categories, setCategories] = useState([]);

    const [fetchCategories, isLoading] = useFetching(async () => {
       const {data} = await TicketCategoryService.getAll();
       setCategories(data);
    });

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <div>
            <Table columns={columns} dataSource={categories} rowKey='id' loading={isLoading}/>
        </div>
    );
};

export default TicketCategories;